import React from 'react'
import { APP_NAME, PHOTOS_ROOT, VIDEOS_ROOT, WEBROOT } from '../../constants/general'
import { Header, HeaderBox, Footer } from '../../components/imports'
import { useNavigate } from 'react-router-dom'
import Annonces from '../../class/Annonces'
import { useEffect } from 'react'
import Func from '../../class/Func'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import { useState } from 'react'
import Variations from '../../components/Variations'
import Categories from '../../class/Categories'
import User from '../../class/User'
import { useTranslation } from 'react-i18next'

function AjouterArticle() {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [array, setArray] = useState([])

  const [error, setError] = useState('')

  const context = useContext(Context)

  const [infos, setInfos] = useState(false)

  const [categories, setCategories] = useState([])
  const [sousCategories, setSousCategories] = useState([])

  const [categorie, setCategorie] = useState(0)

  const [sscategorie, setSsCategorie] = useState(0)

  const [loading, setLoading] = useState(false)

  const [load, setLoad] = useState(false)

  const [photos, setPhotos] = useState([])

  const [videos, setVideos] = useState()

  const [stock, setStock] = useState(1)

  const [conception, setConception] = useState("")

  const [occasion, setOccasion] = useState("")

  const [personnaliser, setPersonnaliser] = useState(0)

  const [livraison_indiv, setLivraison_indiv] = useState(0)

  const [livraison_tarif_special, setLivraison_tarif_special] = useState(0)

  const [required, setRequired] = useState()

  const [descpersonnaliser, setDescPersonnaliser] = useState("")

  const [promotion, setPromotion] = useState(1)

  const [emballage, setEmballage] = useState(0)

  const [handmade, setHandmade] = useState(0)

  const [gratuit, setGratuit] = useState(0)

  const uploadPhotos = (e, setError, setPhotos, photos, setLoading) => {

    let files = e.target.files

    //let newPhotosUrls = []

    if (files.length <= 9) {

      //setPhotos(files)

      Array.from(files).forEach(photo => {

        upload(photo, setPhotos, photos, setError, setLoading)

        //newPhotosUrls.push(URL.createObjectURL(photo))

      })

      //setPhotosUrls(newPhotosUrls)

    } else {

      setError(Text.nombre_de_photos)

      e.target.files = null

    }

  }

  const upload = async (file, setPhotos, photos, setError, setLoading) => {

    if (file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est servenu.")

    setLoading(true)

    let results = await Annonces.photoUpload(file)

    setLoading(false)

    if (results.success) {

      // if(photos == undefined){
      //     photos = []
      // }

      // let new_element = photos
      // new_element.push(results.data)

      // setPhotos([])

      // setInterval(function(){
      //     setPhotos(new_element)
      // }, 1000)

      getImages()

    } else {

      setError(results.message)

    }

  }

  const uploadv = async (file, setVideos, videos, setError, setLoad) => {

    if (file.size == 0 || file.name == null) {

      setError("Impossible d'ajouter cette video une erreur est servenu.")

      window.scrollTo(0, 0)

      return false;

    }

    if (file.size > 52428800) {

      setError("Veuillez choisir un fichier vidéo de moins de 50Mo d'espace disque.")

      window.scrollTo(0, 0)

      return false

    }

    setLoad(true)

    let results = await Annonces.videoUpload(file)

    setLoad(false)

    if (results.success) {

      setVideos(results.data)

    } else {

      setError(results.message)

    }

  }

  const supprimerPhoto = async (e, id, navigate) => {

    let t = e.target.parentNode

    t.style.opacity = 0.5

    let results = await Annonces.supprimerPhoto(id)

    if (results.success) {

      t.style.display = 'none'

    } else {

      // alert(results.message)

      t.style.display = 'none'

      navigate(0)

    }


  }

  const supprimerVideo = async (e, id) => {

    let t = e.target.parentNode

    t.style.opacity = 0.5

    let results = await Annonces.supprimerVideo(id)

    if (results.success) {

      t.style.display = 'none'

    } else {

      alert(results.message)

    }

  }

  const publier = async (e, setError, navigate, array) => {

    e.preventDefault()

    let inputs = e.target

    if(array.length == 1){

      Func.alert("Vous devez ajouter au moins deux choix pour créer des variations dans la fiche produit. Veuillez en ajouter davantage pour continuer ou ne pas mettre de variations")

      return false;
      
    }

    let occasions = []

    inputs.occasion.forEach(elem => {
      if (elem.checked) occasions.push(elem.value)
    })

    const results = await Annonces.publier(inputs, array, occasions)

    if (results.success) {

      navigate('/mesArticles')

    } else {

      let t_msg = ''

      if (results.message == 'm1') t_msg = t('t184')
      if (results.message == 'm2') t_msg = t('t185')
      if (results.message == 'm3') t_msg = t('t186')

      setError(t_msg)

      window.scrollTo(0, 0)

    }

  }

  const uploadVideos = (e, setError, setVideos, videos, setLoad) => {

    let files = e.target.files

    console.log(files)

    uploadv(files[0], setVideos, videos, setError, setLoad)

  }

  const getCategories = async () => {

    let results = await Categories.getCategories()

    setCategories(results.data)

  }

  const getSousCategories = async (parent) => {

    let results = await Categories.getSousCategories(parent)

    setSousCategories(results.data)

  }

  const changeCategorie = (value) => {

    setCategorie(value)

    getSousCategories(value)

  }

  const getImages = async () => {

    let results = await Annonces.getPhotosPublier()

    setPhotos(results.data)

  }

  const getVideos = async () => {

    let results = await Annonces.getVideosPublier()

    setVideos(results.data)

  }

  const myInfos = async () => {

    let results = await User.session(2)

    if (results.success) {

      if (results.data.livraison == null || results.data.delaislivraison == null || results.data.wilaya == '0') {

        Func.alert(t('t189'))

        navigate('/modifierboutique')

        return false

      }

      setInfos(results.data)

    }

  }

  const noWheel = (e) => {

    e.target.blur()

    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)

  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()

    getCategories()

    getSousCategories(12)

    getImages()

    getVideos()

    document.title = t('t126') + " - " + APP_NAME

  }, [])

  return (
    <div>

      <Header />

      <HeaderBox />

      <div className='mt80 p30 cc brca mb30 p20m m_mt_0' id='article'>

        {(error !== '') && <p className="error">{error}</p>}

        <form method="POST" className="form" onSubmit={(e) => publier(e, setError, navigate, array)}>
          {/* array */}
          <h1 className='ff fz18 mb30'>{t('t127')}</h1>

          <label>{t('t128')}</label>

          <input type="text" name="titre" placeholder={t('t128')} required={true} className='bg7 ' />

          <label>{t('t129')}</label>

          <select name="categorie" placeholder={t('t129')} required={true} className='bg7' onChange={e => changeCategorie(e.target.value)}>

            {categories !== [] && categories.map(categorie => <option key={categorie.idC} value={categorie.idC}>{categorie.name}</option>)}

          </select>

          {

            sousCategories.length > 0

            &&

            <>
              <label>{t('t130')}</label>
              <select name='souscategorie' onChange={e => setSsCategorie(e.target.value)}>
                {sousCategories !== [] && sousCategories.map(categorie => <option key={categorie.idC} value={categorie.idC}>{categorie.name}</option>)}
              </select>
            </>

          }

          <label className='mb20'>{t('t131')}</label>
          <p className='mt20'>{t('t132')}</p>

          <div>


            {

              loading

                ?

                <div className='flex jc-center mt50 mb50'>
                  <div className="lds-dual-ring col"></div>
                </div>

                :
                <div className='mb20'>
                  <input type="file" name="uploadPhotosInput" id="uploadPhotosInput" className="inputfile " multiple accept='image/*' maxLength='9' onChange={e => uploadPhotos(e, setError, setPhotos, photos, setLoading)} />
                  <label htmlFor="uploadPhotosInput"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz12'>{t('t135')}</span></label>
                </div>

            }

            {

              photos

              &&

              <div className='grid6 m0a p10 br8 brca m_grid2'>

                {

                  photos.map(image =>

                    <div key={image.idI} style={{ width: '100px', margin: '10px' }} className='brca'>
                      <img src={PHOTOS_ROOT + 'small/' + image.name + '.jpg'} style={{ width: '100px', height: '100px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover' }} />
                      <span className='btn3 cl1 bg9 hover-bg2' onClick={(e) => supprimerPhoto(e, image.idI, navigate)}><span className="icon mr5" >o</span>{t('sup')}</span>
                    </div>

                  )

                }

              </div>

            }



          </div>

          <label className='fz15 mt40'>{t('t133')}</label>
          <p className='lh'>{t('t134')}</p>

          {

            load

              ?

              <div className='flex jc-center mt20 mb50'>
                <div className="lds-dual-ring col"></div>
              </div>

              :

              <div className="mb40">
                <input type="file" name="videos" id="file-8" className="inputfile" accept='video/*' maxLength='9' onChange={e => uploadVideos(e, setError, setVideos, videos, setLoad)} />
                <label htmlFor="file-8"><span className='icon cl2 fz2 mr10'>X</span><span className='cl2 fz12'>{t('t136')}</span></label>
              </div>

          }

          {

            videos

            &&

            <div className='flex ai-center jc-center m0a p10 br8 brca'>

              {

                videos.map(video =>

                  <div style={{ margin: '10px' }}>

                    <video src={VIDEOS_ROOT + video.name} controls
                      style={{ width: '300px', height: '300px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover' }} />
                    <span className='btn3 cl1 bg9 hover-bg2 flex ai-center col-4 flex-start' onClick={(e) => supprimerVideo(e, video.idI)}><span className="icon mr5" >o</span>{t('sup')}</span>

                  </div>

                )
              }

            </div>

          }

          <label className='fz15'>{t('t137')}</label>
          <textarea name='description' className='bg7' required={true} rows={"3"} ></textarea>

          <label className='fz15'>{t('t138')}</label>

          <select type="text" name="typeproduit" id='typeproduit' required={true} className='bg7' onChange={e => setStock(e.target.value)}>
            <option value="1">{t('t139')}</option>
            <option value="0">{t('t140')}</option>
          </select>

          <div className={stock == 1 ? 'dnone' : ''}>

            <label className='fz15'>{t('t141')}</label>
            <input type="number" name="quantity" className='bg7' defaultValue={0} onWheel={e => noWheel(e)} />

          </div>

          <div className={stock == 0 ? 'dnone' : ''}>

            <label className='fz15'>{t('t142')}</label>
            <select name="conception" required={true} className='bg7' onChange={e => setConception(e.target.value)}>
              <option value='t143'>{t('t143')}</option>
              <option value='t144'>{t('t144')}</option>
              <option value='t145'>{t('t145')}</option>
              <option value='t146'>{t('t146')}</option>
              <option value='t147'>{t('t147')}</option>
              <option value='t148'>{t('t148')}</option>
              <option value='t149'>{t('t149')}</option>
              <option value='t150'>{t('t150')}</option>
              <option value='t151'>{t('t151')}</option>
              <option value='t152'>{t('t152')}</option>
            </select>
          </div>

          <div className='grid2'>

            <div>
              <label className='fz15'>{t('prix')}</label>
              <input type="number" name="prix_produit" placeholder={t('prix')} required={true} className='bg7' min={0} onWheel={e => noWheel(e)} />
            </div>

            <div>
              <label className='fz15'>{t('t153')}</label>

              <select name='prixpromotion' onChange={(e) => setPromotion(e.target.value)} defaultValue={0}>
                <option value='0'>{t('t154')}</option>
                <option value='10'>-10%</option>
                <option value='20'>-20%</option>
                <option value='30'>-30%</option>
                <option value='40'>-40%</option>
                <option value='50'>-50%</option>
                <option value='60'>-60%</option>
                <option value='70'>-70%</option>
                <option value='80'>-80%</option>
                <option value='90'>-90%</option>
              </select>

            </div>

          </div>

          <Variations stock={stock} array={array} setArray={setArray} />

          {

            infos.livraison_moi_meme == 2

            &&

            <>

              <label className='fz15 mt50'>{t('t155')}</label>
              <input type="number" name="poids" className='bg7' placeholder={t('t155')} step={0.1} required={true} min={0} onWheel={e => noWheel(e)} />

              <label className='fz15 mt10'>{t('t156')}</label>

              <label className='label'><input type="radio" name='livraison_indiv' className="mr10" value={1} required checked={livraison_indiv === 1} onChange={e => setLivraison_indiv(1)} />{t('oui')}</label>
              <label className='label'><input type="radio" name='livraison_indiv' className="mr10" value={0} required checked={livraison_indiv === 0} onChange={e => setLivraison_indiv(0)} />{t('non')}</label>

            </>


          }

          {

            livraison_indiv == 1

            &&

            <>

              <label className='fz15 mt50'>{t('t157')}</label>

              <label className='label'><input type="radio" name='livraison_tarif_special' className="mr10" value={1} required checked={livraison_tarif_special === 1} onChange={e => setLivraison_tarif_special(1)} />{t('oui')}</label>
              <label className='label'><input type="radio" name='livraison_tarif_special' className="mr10" value={0} required checked={livraison_tarif_special === 0} onChange={e => setLivraison_tarif_special(0)} />{t('non')}</label>

            </>

          }

          {

            livraison_indiv == 1 && livraison_tarif_special == 1

            &&

            <>

              <label className='fz15 mt50'>{t('t158')}</label>

              <input type="number" name="livraison_special_prix" className='bg7' placeholder={t('t159')} onWheel={e => noWheel(e)} />

            </>

          }

          {

            livraison_indiv == 1 && livraison_tarif_special == 0

            &&

            <p>{t('t160')}</p>

          }

          <label className='fz15 mt50'>{t('perso')}</label>

          <label className='label'><input type="radio" name='personnalisation' className="mr10" value={1} required checked={personnaliser === 1} onChange={e => setPersonnaliser(1)} />{t('oui')}</label>
          <label className='label'><input type="radio" name='personnalisation' className="mr10" value={0} required checked={personnaliser === 0} onChange={e => setPersonnaliser(0)} />{t('non')}</label>

          {

            personnaliser === 1

            &&

            <>
              <label className='fz15 mt50'>{t('perso')}</label>
              <div className='flex jc-start'>

                <label className='label mr50'><input type="radio" name='obligatoire' className="mr10" value={1} required checked={required === 1} onChange={e => setRequired(1)} />{t('t161')}</label>
                <label className='label'><input type="radio" name='obligatoire' className="mr10" value={0} required checked={required === 0} onChange={e => setRequired(0)} />{t('t162')}</label>

              </div>

              <label className='fz15'>{t('t163')}</label>

              <textarea name='descpersonnaliser' className='bg7' required rows={"6"} onChange={e => setDescPersonnaliser(e.target.value)}></textarea>

              {
                required === 0

                &&

                <input type="number" name="prixpersonnaliser" placeholder={t('t164')} required className='bg7' min={0} onWheel={e => noWheel(e)} />

              }

            </>

          }

          <div>
            <label className='fz15'>{t('t165')}</label>
            <label className='label'><input type="checkbox" name='handmade' className="mr10" />{t('oui')}</label>
          </div>

          <div className='grid2 mt30 mb10'>

            <div>
              <label className='fz15'>{t('livraisongratuite')}</label>
              <label className='label'><input type="checkbox" name='livraisongratuite' className="mr10" />{t('oui')}</label>
            </div>

            <div>
              <label className='fz15'>{t('t166')}</label>
              <label className='label'><input type="checkbox" name='emballage' className="mr10" />{t('oui')}</label>
            </div>

          </div>


          <label className='fz15'>{t('t167')}</label>

          <div className='flex flex-wrap' style={{ fontSize: '0.8em' }}>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='1' className='mr10' />
              <span>{t('t169_1')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='2' className='mr10' />
              <span>{t('t169')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='3' className='mr10' />
              <span>{t('t170')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='4' className='mr10' />
              <span>{t('t171')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='5' className='mr10' />
              <span>{t('t172')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='6' className='mr10' />
              <span>{t('t173')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='7' className='mr10' />
              <span>{t('t174')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='8' className='mr10' />
              <span>{t('t175')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='9' className='mr10' />
              <span>{t('t176')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='10' className='mr10' />
              <span>{t('t177')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='11' className='mr10' />
              <span>{t('t178')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='12' className='mr10' />
              <span>{t('t180')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='13' className='mr10' />
              <span>{t('t181')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='14' className='mr10' />
              <span>{t('t182')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='15' className='mr10' />
              <span>{t('t183')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='17' className='mr10' />
              <span>{t('t179')}</span>
            </label>

            <label className='col-3'>
              <input type='checkbox' name='occasion' value='16' className='mr10' />
              <span>{t('t183_2')}</span>
            </label>

            <label className='col-3'></label>

          </div>

          {/* <select name="occasion" className='bg7'onChange={(e) => setOccasion()}>
                
                <option value='0'>{t('t168')}</option>
                <option value='2'>{t('t169')}</option>
                <option value='3'>{t('t170')}</option>
                <option value='4'>{t('t171')}</option>
                <option value='5'>{t('t172')}</option>
                <option value='6'>{t('t173')}</option>
                <option value='7'>{t('t174')}</option>
                <option value='8'>{t('t175')}</option>
                <option value='9'>{t('t176')}</option>
                <option value='10'>{t('t177')}</option>
                <option value='11'>{t('t178')}</option>
                <option value='16'>{t('t179')}</option>
                <option value='12'>{t('t180')}</option>
                <option value='13'>{t('t181')}</option>
                <option value='14'>{t('t182')}</option>
                <option value='15'>{t('t183')}</option>
                <option value='16'>{t('t183_2')}</option>

              </select> */}

          <input type="submit" name="save" value={t('enr')} className="btn bg3 cl1 hover-bg2 hover-cl1 col-3" />

        </form>

      </div>


      <Footer />
    </div>
  )
}

export default AjouterArticle

