import React, { useContext, useEffect, useState } from 'react'
import { APP_NAME, AVATAR_ROOT, PHOTOS_ROOT, WEBROOT } from '../constants/general'
import { Header, HeaderBox , Footer } from '../components/imports'
import { Link, Navigate, redirect, useNavigate, useParams } from 'react-router-dom'
import Annonces from '../class/Annonces'
import Func from '../class/Func'
import { useTranslation } from 'react-i18next'
import { Context } from '../class/Context'
import wilaya from '../Json/wilaya.json'
import User from '../class/User'

function Panier() {

    const {t} = useTranslation()

    const context = useContext(Context)

    const navigate = useNavigate()

    const [code, setCode] = useState(false);

    const [message, setMessage] = useState(false);

    const [panier, setPanier] = useState([]);

    const [boutique, setBoutique] = useState([]);

    const [livraison, setLivraison] = useState(0)

    const [delivery, setDelivery] = useState()

    const [qntt, setQntt] = useState()

    const [prixLivraison, setPrixLivraison] = useState()
  
    const [loading, setLoading] = useState(false)

    const [pointsRelais, setPointsRelais] = useState({}) 

    const [infos, setInfos] = useState(false)

    const toggleShow = () =>{

        setCode(!code)
    
    }

    const toggleShowMessage = () =>{

        setMessage(!message)

    }

    const supprimer = (p,i,data) =>{

        // console.log(data)

        const total = [...panier]

        const b = [...boutique]

        const shop = b.filter((j) => j.boutique_id ===  i.boutique_id);

        const iIndex = total.findIndex((j) => j.annonce_id === p.annonce_id);

        total.splice(iIndex, 1)

        localStorage.setItem('panier', JSON.stringify(total));
    
        // setPanier(total)
   
        setPanier(JSON.parse(localStorage.getItem("panier")))

        setBoutique(JSON.parse(localStorage.getItem("panier")))

        if( total.length === 0 ){

            total.splice(shop,1)
          
            // console.log(total.splice(shop,1))

            localStorage.setItem('panier', JSON.stringify(total))

        }else{

            console.log("not empty")

        }

        setTimeout(() => {
            calculeLivraisons()
        }, 100)

    }

    const Commander = async (e) =>{

        e.preventDefault()

        let pass = true

        JSON.parse(localStorage.panier).map(elem => {

            if(elem.delivery == '2' && (elem.pointsrelais == undefined || elem.pointsrelais == null || elem.pointsrelais == 0)){

                Func.alert(t('t338'))

                pass = false

                return false

            }

        })

        if(!pass) return false

        let results = await Annonces.panier(localStorage.panier)

        if(results.success){

            Func.alert(t('t339'))

            localStorage.panier = '[]'

            navigate('/commandes/0')

        }else{

            if(results.message !== undefined && results.message !== null && results.logout == undefined) Func.alert(results.message)

            if(results.logout !== undefined) navigate('/commander-invite?r=panier')

            // if(results.redirect !== undefined) navigate(results.redirect)

        }
        
    }

    const Qnty = (e, i , p) =>{

        let  input  = e.target.value

        let arr = JSON.parse(localStorage.getItem('panier') || '[]')

        arr.filter((item) =>(i.boutique_id === item.id_user && item.annonce_id === p.annonce_id)).map( function(element) {

            element.qnt = input 

            setQntt(qntt)

            localStorage.setItem('panier', JSON.stringify(arr));

            setPanier(JSON.parse(localStorage.getItem("panier")))

            setBoutique(JSON.parse(localStorage.getItem("panier")))
            
        })

        setTimeout(() => {
            calculeLivraisons()
        }, 100)

    }

    const Delivery = (e, i) =>{

        let  input  = e.target.value

        let arr = JSON.parse(localStorage.getItem('panier') || '[]')

        arr.filter((item) =>(i.boutique_id === item.id_user)).map( function(element) {

            element.delivery = input

            setDelivery(input)

            if(element.livraison_gratuite ==  1){

                element.prixLivraison = 0

                if(input == "2"){

                    getPointsRelais(element.boutique_id, element.livraison)

                }

            }else{

                if(input == "1"){

                    element.prixLivraison = i.dom
                
                }else{

                    element.prixLivraison = i.relais

                    getPointsRelais(element.boutique_id, element.livraison)

                }

            }
        
            setPrixLivraison(prixLivraison)
        
            setTimeout(calculeLivraisons, 200)

            localStorage.setItem('panier', JSON.stringify(arr))

            setPanier(JSON.parse(localStorage.getItem("panier")))

            setBoutique(JSON.parse(localStorage.getItem("panier")))


            // calculeLivraisons()
        
        })
          
    } 

    const addMessage = (e, i) =>{

        let message = e.target.parentNode.querySelector('textarea').value

        if(message == '') Func.alert(t('t340'))

        let arr = JSON.parse(localStorage.getItem('panier') || '[]')

        arr.filter((item) =>(i.boutique_id === item.id_user)).map( function(element) {

            element.message = message

            localStorage.setItem('panier', JSON.stringify(arr));

            setPanier(JSON.parse(localStorage.getItem("panier")))

            setBoutique(JSON.parse(localStorage.getItem("panier")))
            
        });

    }

    const changePointRelais = (e, i) =>{

        let selected = e.target.value

        let arr = JSON.parse(localStorage.getItem('panier') || '[]')

        arr.filter((item) =>(i.boutique_id === item.id_user)).map( function(element) {

            element.pointsrelais = selected

            localStorage.setItem('panier', JSON.stringify(arr));

            setPanier(JSON.parse(localStorage.getItem("panier")))

            setBoutique(JSON.parse(localStorage.getItem("panier")))
            
        });

    }

    const getPointsRelais = async (boutique_id, livraison) => {
    
        let wilaya = 0

        if(localStorage.getItem('wilaya') == undefined){

            Func.alert('Ajouter une wilaya')

            return false

        }

        wilaya = localStorage.getItem('wilaya')

        setPointsRelais(true)

        const results = await Func.fetch("annonces/pointsRelais", {boutique_id, livraison, wilaya})

        if(results.success){

            pointsRelais[boutique_id] = results.data

            setPointsRelais(pointsRelais)            
    
        }
        
        // else{
    
        //     setPointsRelais(t('t341'))
    
        // }
    
    }

    const filterDuplicates = (i) =>{

        return panier.filter((item) =>(i.boutique_id === item.id_user) || panier.includes(item.annonce_id) );

    }

    const findDuplicatesRows = () =>{  

        return boutique.filter((item, index) => boutique.findIndex(data => data.boutique_id === item.boutique_id ) === index);
         
    }

    const calculeLivraisons = () => {

        let boutiques_id = document.querySelectorAll('.__boutique_liv')

        let prix_de_livraison = 0;
        
        boutiques_id.forEach(boutique => {

            let livraison_total = 0
            let poids_total = 0

            let liv_inidv = 0

            let art_spec_liv_prix = 0

            let qnt = 1

            let delivery = parseInt(boutique.dataset.delivery)
            let prix_dom = parseInt(boutique.dataset.dom)
            // let prix_relais = parseInt(boutique.dataset.relais)

            if(delivery == "1"){
            
                prix_dom = parseInt(boutique.dataset.dom)

            }else{

                prix_dom = parseInt(boutique.dataset.relais)

            }

            if(boutique.dataset.moimeme == 1 && boutique.dataset.liv_gratuite == 0){

                prix_de_livraison = prix_de_livraison + prix_dom

                return false

            }

            let articles_by_poids = document.querySelectorAll('[data-poidsarticle="boutique_id_'+ boutique.dataset.boutique_id +'"]')

            articles_by_poids.forEach(art => {

                if(art.dataset.liv_gratuite == 0){

                    if(art.dataset.indiv == 1){

                        if(art.dataset.prix == 0){
                            
                            if(parseInt(art.dataset.poids) < 5){
                                
                                art_spec_liv_prix = art_spec_liv_prix * parseInt(art.dataset.qnt) + prix_dom

                            }else{

                                art_spec_liv_prix = art_spec_liv_prix + (parseInt(art.dataset.poids) * parseInt(art.dataset.qnt) - 5) * 50 + prix_dom 

                            }
        
                        }else{

                            if(art.dataset.livraison_special_prix > 0){

                                art_spec_liv_prix = art_spec_liv_prix + parseInt(art.dataset.prix) * parseInt(art.dataset.qnt)

                            }else{

                                if(parseInt(art.dataset.poids) < 5){
                                    
                                    art_spec_liv_prix = art_spec_liv_prix + prix_dom * parseInt(art.dataset.qnt)

                                } else{

                                    art_spec_liv_prix = art_spec_liv_prix + (parseInt(art.dataset.poids) * parseInt(art.dataset.qnt) - 5) * 50 + prix_dom

                                }

                            }
        
        
                        }

                    }else{

                        liv_inidv = 1

                        poids_total = poids_total + parseInt(art.dataset.poids) * parseInt(art.dataset.qnt)

                    }

                }

            })

            if(poids_total > 5){

                poids_total = poids_total - 5

                livraison_total = art_spec_liv_prix + poids_total * 50

            }else{

                poids_total = 0

                livraison_total = art_spec_liv_prix

            }

            if(liv_inidv == 1) livraison_total = livraison_total + prix_dom

            if(liv_inidv == 0) if(document.querySelector('[data-livraisonstandard="boutique_id_'+ boutique.dataset.boutique_id +'"]') !== null)document.querySelector('[data-livraisonstandard="boutique_id_'+ boutique.dataset.boutique_id +'"]').style.display = 'none' 

            if(document.querySelector('[data-fraislivraison="boutique_id_'+ boutique.dataset.boutique_id +'"]') !== null) document.querySelector('[data-fraislivraison="boutique_id_'+ boutique.dataset.boutique_id +'"]').innerText = parseInt(livraison_total).toLocaleString('fr-FR') + ' DA'
            if(document.querySelector('[data-fraislivraisonsupp="boutique_id_'+ boutique.dataset.boutique_id +'"]') !== null) document.querySelector('[data-fraislivraisonsupp="boutique_id_'+ boutique.dataset.boutique_id +'"]').innerText = (poids_total * 50) + ' DA'
            
            if(poids_total == 0) if(document.querySelector('[data-fraislivraisonsuppbox="boutique_id_'+ boutique.dataset.boutique_id +'"]') !== null) document.querySelector('[data-fraislivraisonsuppbox="boutique_id_'+ boutique.dataset.boutique_id +'"]').style.display = 'none'

            prix_de_livraison = prix_de_livraison + parseInt(livraison_total)

        })

        setLivraison(prix_de_livraison)

    }

    let sousTotal = panier.reduce((acc, item) => acc +  (!qntt ? item.qnt : qntt)  *  item.prix , 0)

    let Total = (parseInt(sousTotal) + parseInt(livraison))

    const myInfos = async () => {

        let results = await User.session(1)
    
        if(results.success) setInfos(results.data)
    
    }

    const editInfos = async () => {

        let input = document.getElementById('livraison_form')

        if( input.elements.tele.value !== "" && input.elements.adresse.value !=="" &&  input.elements.wilaya.value !== ""){
            
            let results = await Func.fetch("users/modifierLivraison", {tele: input.tele.value, adresse: input.adresse.value, wilaya: input.wilaya.value})

            if(results.success){
                
                if(results.wilaya !== localStorage.getItem('wilaya') && localStorage.getItem('wilaya') !== '0'){
                    
                    Func.alert("Vous avez changé de wilaya, ce qui a entraîné la suppression des articles de votre panier. La disponibilité des produits et des options de livraison peut varier selon les wilayas. Merci de reconstituer votre panier pour votre nouvelle localisation.")
                    
                    localStorage.panier = '[]'

                    localStorage.wilaya = results.wilaya
                    
                    navigate('/')

                }else{
                    localStorage.wilaya = results.wilaya

                    Func.alert(t('t380'))
                }

            }else{

                Func.alert(results.message)

            }

        }else{

            Func.alert("Veuillez entrer toutes les informations demandées")

        }
    
    }

    useEffect(() => {

        document.title = t('t342') + " - " + APP_NAME

        setPanier(JSON.parse(localStorage.getItem("panier")))

        setBoutique(JSON.parse(localStorage.getItem("panier")))

        setTimeout(calculeLivraisons, 1000)

        window.scrollTo(0, 0)

        if(context.session == true) myInfos()
  
    }, [])

  return (

    <div>

        <Header/>

        <HeaderBox/>
        
        <div className='c pos-r'>

            <div className='mt80 m_mt_0'>
                <>
                    <h1 className='ff fz2 mb30 cl8 m20m'><span class="icon mr10">m</span>{t('t342')} <span class="fz07">({JSON.parse(localStorage.getItem("panier")).length} {t('t343')})</span></h1>
                    <form method='POST' className='form' onSubmit={e => Commander(e)}>
                        <div className='flex'>

                            <div className='mb20 col-7 panier'>
                            {               
                                findDuplicatesRows(boutique).map((i, data)=>(

                                    <div class="brca p10 br20 mb30 bg7" key={data}> 
                                        {
                                            filterDuplicates(i).map((p, index) => 

                                            <div class="panier_list flex ai-center mb30 pb10 m_flex" key={index}>

                                                <Link to={"/article/" + p.annonce_id } className='col-2'> <img src={PHOTOS_ROOT + 'full/' + p.boutique_img + '.jpg'} className="br20 panier-img mb30m" /></Link>
                                        
                                                    <div class="col-45 grid M fz12 mb30m mt10 m_fz08">
                                                        <span class="mb5" data-qnt={ p.qnt } data-poids={ p.poids } data-prix={ (p.livraison_indiv == 1 && p.livraison_special == 1) ? p.livraison_special_prix : parseInt(p.dom) + parseInt(p.poids_prix) * 50 } data-indiv={ p.livraison_indiv } data-livraison_special_prix={p.livraison_special_prix} data-poidsarticle={'boutique_id_' + i.boutique_id} data-liv_gratuite={p.livraison_gratuite}>Ref : <span className='fz08 ml5'>{ p.article_titre }</span></span>
                                                        
                                                        {

                                                            p.idO !== ""

                                                            &&

                                                                p.option_type == "t293" || p.option_type == "t294"
                                                                    
                                                                    ?
                                                                    
                                                                    p.option_type !== undefined && <span class="mb5">{ t(p.option_type) } : <span className="circl circl_square" style={{backgroundColor: p.option_value, display: 'inline-block', margin: '0'}}></span></span>

                                                                    :

                                                                    p.option_type !== undefined && <span class="mb5">{ t(p.option_type) } : { p.option_value }</span>

                                                        }

                                                        <span class="mb5 ">{t('prix')} :<span className='fz09 ml5'>{ parseInt(p.prix).toLocaleString('fr-FR') } DA </span></span>
                                                        { p.emballage == true && <span class="mb5 ">{t('t81')} : {t('oui')}</span> }
                                                        <span className='mb5'>{t('quantite')} :<input type="number" name="qntt" min="1" className='num ml5 mb0' defaultValue={p.qnt}  onChange={e => Qnty(e , i, p)}/></span>
                                                        { 
                                                        
                                                            p.livraison_gratuite == 1
                                                            
                                                            ?
                                                            
                                                            <p class="cl4 fz09 mb5">{t('livraisongratuite')}</p>
                                                        
                                                            :

                                                            <>
                                                            
                                                            { 
                                                        
                                                                p.livraison_indiv && p.poids > 0 && p.livraison_special == 1 && p.livraison_special_prix > 0

                                                                &&

                                                                p.moimeme == 0 && <span class="mb5">{t('t344')} :<span className='fz09 ml5' data-prix={p.livraison_special_prix} data-boutiqueliv={'boutique_liv_' + p.boutique_id}>{ parseInt(p.livraison_special_prix).toLocaleString('fr-FR') } DA </span></span>

                                                            }

                                                            {
                                                            
                                                                p.livraison_indiv == 1 && p.poids > 5 && p.livraison_special == 0

                                                                &&

                                                                p.moimeme == 0 && <span class="mb5 ">{t('t344')} :<span className='fz09 ml5' data-prix={ parseInt(p.dom) + parseInt((p.poids - 5) * 50) } data-boutiqueliv={'boutique_liv_' + p.boutique_id}>{ parseInt(parseInt(p.dom) + parseInt((p.poids - 5) * 50)).toLocaleString('fr-FR') } DA </span></span>

                                                            }

                                                            </>

                                                        }

                                                    </div>
                                    
                                                <div className='col-3 mb30m'>
                                                    <span className='ai-center cl9 hover-cl9 fz11 cp dl_btn' onClick={e => supprimer(p ,i, data)}><span class="icon mr10" >O</span>{t('sup')}</span>
                                                </div>
                                            </div> 

                                        )

                                        }
                                            <div className='flex fz09'>
                                    
                                                <div className='col-5'>

                                                    <Link to={"/boutique/" + i.boutique_id } className='flex ai-center flex-start pos-r b_name'>
                                
                                                        <img src={ AVATAR_ROOT + i.boutique_avatar + '.jpg'} className=" avatar-small"/>
                                                        <h3 className='ml10 cl8 mt30 fz11 fw5 name __boutique_liv' data-boutique_id={ i.boutique_id } data-dom={parseInt(i.dom)} data-relais={parseInt(i.relais)} data-delivery={parseInt(i.delivery)} data-moimeme={i.moimeme} data-liv_gratuite={i.livraison_gratuite}>{i.boutique_name}</h3>
                                                            
                                                    </Link>
                                                    
                                                    <p className='cl8 mt20 mb10 cp hover-cl3' onClick={toggleShowMessage}><span class="icon mr10">G</span>{t('t345')}</p>
                                                        
                                                        {

                                                            (message == true || i.message !== undefined)

                                                            &&

                                                            <div className='mb40'>

                                                                <textarea type='text' name="..." placeholder="..." style={{marginBottom: '10px'}}>{i.message}</textarea>

                                                                <span className='btn bg3 cl1' onClick={e => addMessage(e, i)}>{ i.message == !undefined ? t('modifier') : t('enr') }</span>

                                                            </div>

                                                        }
                                                    
                                                </div>

                                                <div className='col-3'>

                                                    {/* Type de livraisons ---------------------------------- */}
                                                    { i.moimeme == "1" && <h4 className='cl2 fz9 fw5 mb10 mt5'>{t('t346')}</h4> }
                                                    
                                                    { i.moimeme == "0" && i.autre_partenaire !== "" && <h4 className='cl2 fz9 fw5 mb10 mt5'>{t('t332')} { i.autre_partenaire }</h4> }
                                                    
                                                    { i.moimeme == "0" && i.autre_partenaire == "" && i.dom !== "/" && <h4 className='cl2 fz9 fw5 mb10 mt5' data-livraisonstandard={'boutique_id_' + i.boutique_id}>{t('t347')} { i.livraison } : { i.dom } DA</h4> }
                                                    { i.moimeme == "0" && i.autre_partenaire == "" && i.dom == "/" && <h4 className='cl2 fz9 fw5 mb10 mt5' data-livraisonstandard={'boutique_id_' + i.boutique_id}>{t('t348')} { i.livraison } : { i.relais } DA</h4> }

                                                    {

                                                        i.moimeme == "1"

                                                        ?

                                                            <p className='mb10'>{t('fraisliv')} : <span className='fw7' data-fraislivraison={'boutique_id_' + i.boutique_id}>{ i.dom } DA</span></p>

                                                        :

                                                        <>

                                                            <>
                                                                <h4 className='cl2 fz9 fw5 mb10 mt5' data-fraislivraisonsuppbox={'boutique_id_' + i.boutique_id}>{t('t350')} : <span data-fraislivraisonsupp={'boutique_id_' + i.boutique_id}>...</span></h4>
                                                                <p className='mb10'>{t('t349')} : <span className='fw7 data-fraislivraison' data-fraislivraisonprix={ i.prixLivraison } data-fraislivraison={'boutique_id_' + i.boutique_id}>{ i.prixLivraison } DA</span></p>
                                                            </>

                                                            <select id="livraison" name="delivery" onChange={e => Delivery(e, i)} defaultValue={i.delivery}>
                                                            
                                                                { i.dom !== '/' && <option value="1">{t('t351')}</option> }

                                                                {
                                                                
                                                                    i.autre_partenaire !== "" || i.relais == '/' ? <option value="2" className='dnone'></option> : <option value="2">{t('t352')}</option>
                                                                
                                                                }

                                                            </select>
                                                        
                                                        </>
                                                    }


                                                        {

                                                            pointsRelais == true

                                                            &&

                                                            <div className='flex jc-center mt50 mb50'>
                                                                <div className="lds-dual-ring col"></div>
                                                            </div>

                                                        }

                                                        {/* {

                                                            typeof pointsRelais == 'string'

                                                            &&

                                                            <p>{ pointsRelais }</p>

                                                        } */}

                                                        {

                                                        Object.keys(pointsRelais).length > 0 && typeof pointsRelais == 'object' && i.delivery == 2 && pointsRelais[i.boutique_id] !== undefined

                                                        &&

                                                        pointsRelais[i.boutique_id].map(pr =>
                                                        
                                                            <div className='p5 brc1solid brc3 br10 flex jc-start ai-center mb10 fz08 m_flex' key={pr.idPR}>

                                                                <div>
                                                                    <input type='radio' className='mr10 mt20'name={'pointrelais[' + i.boutique_id + ']'} value={pr.idPR} checked={i.pointsrelais == pr.idPR ? true : false} onChange={ e => changePointRelais(e, i) } />
                                                                </div>

                                                                <div>
                                                                    <h3>{pr.nom}</h3>
                                                                    <p>{pr.adresse}</p>
                                                                </div>

                                                            </div>

                                                        )

                                                        }
                                        
                                                </div>
                                                
                                            </div>
                                        
                                    </div>   
                                
                                )

                            
                            )}
                            </div>


                            {

                            JSON.parse(localStorage.getItem("panier")).length > 0

                            &&

                            <div className='col-28 sticky'>
                            <div className='fz1 bg7 p30 br20  M fz11 total'>
                        
                                
                                <div className='flex mb10 fz09'>
                                    <p>{t('t353')}</p>
                                    <p> { parseInt(sousTotal).toLocaleString('fr-FR') } DA</p>
                                </div>

                                <div className='brcb mb20 flex fz09'>
                                    <p>{t('t349')}</p>
                                    <p> { parseInt(livraison).toLocaleString('fr-FR') } DA </p>
                                </div>

                                <div className='flex'>

                                    <p className='fw7'>{t('total')} :</p>
                                    
                                    <p className='cl3 fw7'> { parseInt(Total).toLocaleString('fr-FR') } DA</p>
                                    
                                </div>
                            
                            </div>

                            {
                                infos &&

                                <form className='fz1 bg7 p30 br20 M fz11 total mt20' id='livraison_form'>
                            
                                    <p className='mb20 fz12'>Informations de livraison</p>
                                    
                                    <div className='fz09'>
                                        <p className='mb10'>{t('t214')}</p>
                                        <input type='text' placeholder='05/06/07 ...' defaultValue={infos.tele} required={true} id='tele'/>
                                    </div>

                                    <div className='fz09'>
                                        <p className='mb10'>{t('t384')}</p>
                                        <input type='text' placeholder={t('adresse')} defaultValue={infos.adresse} required={true} id='adresse'/>
                                    </div>

                                    <div className='fz09'>
                                        <p className='mb10'>{t('t196')}</p>
                                        <select name="wilaya" required={true} id='wilaya'>

                                        {

                                            wilaya.map((getwilaya, index)  => (

                                                <option value={getwilaya.id} key={index} selected={localStorage.getItem('wilaya') == getwilaya.id ? true : false}>{getwilaya.name}</option>

                                            ))

                                        }

                                        </select>
                                    </div>

                                    <span className='btn bg3 cl1 hover-bg2' onClick={(e) => editInfos(e)}>{t('enr')}</span>
                                
                                </form>

                            }

                            <input type="submit" className='btn bg3 cl1 hover-bg2 mb20' value={t('t354')} />

                            </div>

                            }

                        </div>
                    </form>                                
                </>
     
            
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Panier