import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Func from '../../class/Func'
import { API_ROOT, APP_NAME } from '../../constants/general'
import { useTranslation } from 'react-i18next'

function ReclamationNoConnexion() {

  const {t} = useTranslation()

  const id = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const [reclamation, setReclamation] = useState(0)
  const [demande, setDemande] = useState(0)
  const [reclamationText, setReclamationText] = useState('')

  const [imageAdded, setImageAdded] = useState(0)

  const [loading, setLoading] = useState(false)

  const envoyerReclamation = async (e) => {

    e.preventDefault()

    if(searchParams.get("pid") == null){

      Func.alert("Impossible d'envoyer la reclamation, veuillez réessayer")

      navigate('/')

      return false

    }

    let file = e.target.uploadPhotosInput.files[0]
    
    if(file == undefined || file.size == 0 || file.name == null){

      Func.alert(t('t387'))

      return false

    }

    setLoading(true)
  
    let formdata = new FormData()
    formdata.append('image', file)
    formdata.append('id', id.id)
    formdata.append('reclamation', reclamation)
    formdata.append('demande', demande)
    formdata.append('reclamationText', reclamationText)
    formdata.append('pid', searchParams.get("pid"))
        
    fetch(API_ROOT + "annonces/commandes/ajouterReclamationNoConnexion", {
        method: "POST",
        body: formdata
    }).then(response => response.json())
    .then(results => {

      if(results.success){

        Func.alert(t('t388'))
  
        navigate('/')
  
      }else{

        let t_msg = 'ERROR'

        if(results.message == 'err1') t_msg = t('t389')
        if(results.message == 'errrelclam') t_msg = "Impossible d'envoyer la reclamation, veuillez réessayer"
  
        Func.alert(t_msg)

        setLoading(false)
  
      }

    })
  
  }

  useEffect(() => {

    document.title = t('t385') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 m_mt_0" onSubmit={ envoyerReclamation }>

          <h1 className='ff fz18 mb30'>{t('t390')}</h1>
  
          <label className='mb30'>{t('t391')}</label>
          
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10 mb0" value={t('t392')} checked={reclamation == t('t392') ? true : false} onChange={ e => setReclamation(e.target.value) } required={true}/>{t('t392')}</label>
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10 mb0" value={t('t393')} checked={reclamation == t('t393') ? true : false} onChange={ e => setReclamation(e.target.value) } />{t('t393')}</label>
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10 mb0" value={t('t394')} checked={reclamation == t('t394') ? true : false} onChange={ e => setReclamation(e.target.value) } />{t('t394')}</label>

          <label className='mt30'>{t('t395')}</label>
          
          <label className='label-radio mt30'><input type="radio" name='demande' className="mr10" value={t('t396')} checked={demande == t('t396') ? true : false} onChange={ e => setDemande(e.target.value) } required={true}/>{t('t396')}</label>
          <label className='label-radio'><input type="radio" name='demande' className="mr10" value={t('t397')} checked={demande == t('t397') ? true : false} onChange={ e => setDemande(e.target.value) } />{t('t397')}</label>
          <label className='label-radio'><input type="radio" name='demande' className="mr10 mb0" value={t('t398')} checked={demande == t('t398') ? true : false} onChange={ e => setDemande(e.target.value) } />{t('t398')}</label>


          <label className='mt30 mb30'>{t('t399')}</label>
          <textarea value={reclamationText} onChange={ e => setReclamationText(e.target.value) } className='bg7' placeholder={t('t400')}></textarea>

          {

            imageAdded == 1 && <p className='cl4 mb30'>{t('t369')}</p>

          }

          <p className='fz09 mb30'>{t('t401')}</p>

          <div className='mb20'>
            <input type="file" name="uploadPhotosInput" id="uploadPhotosInput" className="inputfile" data-multiple-caption="{count} files selected" accept='image/*'  maxLength='1' style={{display: 'none'}} onChange={ e => setImageAdded(1) }/>
            <label for="uploadPhotosInput"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz12'>{t('t370')}</span></label>    
          </div>


          {

            loading

            ?

            <div className='flex jc-center mt50 mb50'>
              <div className="lds-dual-ring col"></div>
            </div>

            :

            <input type="submit" name="valider" value={t('t371')} className="btn bg2 cl1 hover-bg3 hover-cl1 mb30"/>

          }
          
        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default ReclamationNoConnexion