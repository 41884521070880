import { Context } from "./class/Context";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './style/style.css'

import Home from "./pages/Home";
import Article from "./pages/Article";
import Connexion from "./pages/Connexion";
import Inscription from "./pages/Inscription";
import InscriptionClient from "./pages/InscriptionClient";
import Panier from "./pages/Panier";
import Apropos from "./pages/Apropos";
import Favoris from "./pages/compte/Favoris";
import Boutique from "./pages/Boutique/Boutique";
import EditBoutique from "./pages/Boutique/EditBoutique";
import MesArticles from "./pages/Boutique/MesArticles";
import Statistiques from "./pages/Boutique/Statistiques";
import AjouterArticle from "./pages/Boutique/AjouterArticle";
import EditArticle from "./pages/Boutique/EditArticle";
import NotesAvis from "./pages/Boutique/NotesAvis";
import CommandesCompte from "./pages/compte/Commandes";
import Litige from "./pages/compte/Litige";
import DonnerAvis from "./pages/compte/DonnerAvis";
import Parametres from "./pages/compte/Parametres";
import ParametresB from "./pages/Boutique/ParametresB";
import Politique from "./pages/Pages/Politique";
import Categorie from "./pages/Categorie";
import Preinscription from "./pages/Preinscription";
import User from "./class/User";
import Page_404 from "./pages/Page_404";
import Tarifs from "./pages/Boutique/Tarifs";
import Discussion from "./pages/discussions/Discussion";
import Discussions from "./pages/discussions/Discussions";
import EnvoyerMessage from "./pages/discussions/EnvoyerMessage";
import Search from "./pages/Search";

import Commandes from "./pages/Boutique/commandes/Commandes";
import AnnulerCommande from "./pages/Boutique/commandes/AnnulerCommande";
import Supprimer from "./pages/Boutique/article/Supprimer";
import Reclamation from "./pages/compte/Reclamation";
import SuspendreBoutique from "./pages/Boutique/SuspendreBoutique";
import RetourCommande from "./pages/Boutique/commandes/RetourCommande";
// import RepondreCommande from "./pages/Boutique/commandes/RepondreCommande";
import AjusterLivraison from "./pages/Boutique/commandes/AjusterLivraison";
import Occasion from "./pages/Occasion";
import NoteAvisReponse from "./pages/Boutique/NotesAvisReponse";
import RetirerProduit from "./pages/Boutique/commandes/RetirerProduit";
import LivraisonRefuse from "./pages/compte/LivraisonRefuse";
import Factures from "./pages/Boutique/Factures";
import Pages from "./pages/Pages/Pages";
import ConnexionGoogle from "./pages/social/ConnexionGoogle";
import ConnexionFacebook from "./pages/social/ConnexionFacebook";
import MotDePasse from "./pages/MotDePasse";
import ArticlesPhares from "./pages/ArticlesPhares";
import Remboursement from "./pages/Boutique/commandes/Remboursement";
import CommanderInvite from "./pages/CommanderInvite";
import ReclamationNoConnexion from "./pages/compte/ReclamationNoConnexion";


function App() {

  const [session, setSession] = useState(false)

  useEffect(() => {

    User.connected(setSession)

  }, [])

  return (

    <div className="App">

      <Context.Provider value={{ session: session, setSession: setSession }}>

        <Router>

          <Routes>

            <Route exact path="/" element={<Home />}></Route>

            <Route path="/connexion" element={<Connexion />}></Route>
            <Route path="/commander-invite" element={<CommanderInvite />}></Route>
            <Route path="/inscription" element={<Preinscription />}></Route>
            <Route path="/inscriptionClient" element={<InscriptionClient />}></Route>
            <Route path="/inscription_artisan" element={<Inscription />}></Route>
            <Route path="/recuperer-mot-de-passe" element={<MotDePasse />}></Route>
            <Route path="/article/:id" element={<Article />}></Route>
            <Route path="/favoris" element={<Favoris />}></Route>
            <Route path="/apropos" element={<Apropos />}></Route>
            <Route path="/boutique/:id" element={<Boutique />}></Route>
            <Route path="/search" element={<Search />}></Route>

            {

              session

              &&

              <>

                {

                  localStorage.getItem("user_type") == 2

                    ?
                    <>

                      {/* Boutique */}

                      <Route path="/modifierboutique" element={<EditBoutique />}></Route>

                      <Route path="/mesArticles" element={<MesArticles />}></Route>
                      <Route path="/ajouter-article" element={<AjouterArticle />}></Route>

                      <Route path="/modifierArticle/:id" element={<EditArticle />}></Route>
                      <Route path="/supprimer-annonce/:id" element={<Supprimer />}></Route>

                      <Route path="/mes-notes-avis" element={<NotesAvis />}></Route>
                      <Route path="/reponse-avis/:id" element={<NoteAvisReponse />}></Route>
                      <Route path="/mes-factures" element={<Factures />}></Route>
                      <Route path="/statistiques" element={<Statistiques />}></Route>
                      <Route path="/parametres_Boutique" element={<ParametresB />}></Route>
                      <Route path="/tarifs" element={<Tarifs />}></Route>

                      <Route path="/suspendre-boutique" element={<SuspendreBoutique />}></Route>

                      {/* Commandes */}
                      <Route path="/commandes/:url" element={<Commandes />}></Route>

                      <Route path="/commandes/annuler-commande/:id" element={<AnnulerCommande />}></Route>

                      <Route path="/commandes/retourner-commande/:id" element={<RetourCommande />}></Route>

                      {/* <Route path="/commandes/repondre-commande/:id" element={<RepondreCommande/>}></Route> */}

                      <Route path="/commandes/ajuster-livraison/:id" element={<AjusterLivraison />}></Route>

                      <Route path="/commandes/retirer-article/:id" element={<RetirerProduit />}></Route>

                      <Route path="/commandes/remboursement/:id" element={<Remboursement />}></Route>

                    </>
                    :

                    <>

                      {/* Client */}

                      <Route path="/commandes/:url" element={<CommandesCompte />}></Route>
                      <Route path="/parametres" element={<Parametres />}></Route>
                      <Route path="/commandes/donner-avis/:id" element={<DonnerAvis />}></Route>
                      <Route path="/commandes/reclamation/:id" element={<Reclamation />}></Route>
                      <Route path="/commandes/litige/:id" element={<Litige />}></Route>
                      <Route path="/commandes/livraison-refuse/:id" element={<LivraisonRefuse />}></Route>

                    </>
                }

                <Route path="/discussions" element={<Discussions />}></Route>
                <Route path="/discussion/:id" element={<Discussion />}></Route>
                <Route path="/envoyer-message/:id" element={<EnvoyerMessage />}></Route>

              </>

            }

            <Route path="/reclamation-no-connexion/:id" element={<ReclamationNoConnexion />}></Route>

            <Route path="/panier" element={<Panier />}></Route>

            <Route path="/connexion-google" element={<ConnexionGoogle />}></Route>
            <Route path="/connexion-facebook" element={<ConnexionFacebook />}></Route>

            {/* Categorie */}
            <Route path="/categorie/:url" element={<Categorie />}></Route>

            {/* Occasion */}
            <Route path="/occasion/:url" element={<Occasion />}></Route>

            {/* Phares */}
            <Route path="/articles-phares" element={<ArticlesPhares />}></Route>

            <Route path="/politique" element={<Politique />}></Route>

            <Route path="/page/:url" element={<Pages />}></Route>
            <Route path='/404' element={<Page_404 />} />
            <Route path='*' element={<Page_404 />} />

          </Routes>

        </Router>

      </Context.Provider>

    </div>
  );
}

export default App;