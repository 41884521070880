import React, { useRef } from 'react'
import { APP_NAME, PHOTOS_ROOT, VIDEOS_ROOT, WEBROOT } from '../../constants/general'
import { Header, HeaderBox , Footer } from '../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Annonces from '../../class/Annonces'
import { useEffect } from 'react'
import Func from '../../class/Func'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import { useState } from 'react'
import Categories from '../../class/Categories'
import User from '../../class/User'
import VariationsEdit from '../../components/VariationsEdit'
import { useTranslation } from 'react-i18next'

const uploadPhotos = (e, id, setError, setPhotos, photos, setLoading) => {

  let files = e.target.files

  //let newPhotosUrls = []

  if(files.length <= 9){

      //setPhotos(files)

      Array.from(files).forEach(photo => {

          upload(id, photo, setPhotos, photos, setError, setLoading)
          
          //newPhotosUrls.push(URL.createObjectURL(photo))

      })

      //setPhotosUrls(newPhotosUrls)

  }else{

      setError(Text.nombre_de_photos)

      e.target.files = null

  }

}

const upload = async (id, file, setPhotos, photos, setError, setLoading) => {

  if(file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est servenu.")

  if(file.size > 5242880){

    setError("Veuillez choisir une photo de moins de 5Mo d'espace disque.")

    window.scrollTo(0, 0)

    return false

  }

  setLoading(true)

  let results = await Annonces.photoUpload(file, id)

  setLoading(false)

  if (results.success){

    if(photos == undefined){
        photos = []
    }
    
    let new_element = photos
    new_element.push(results.data)

    setPhotos([])

    setInterval(function(){
        setPhotos(new_element)
    }, 1000)

  }else{

      setError(results.message)

      window.scrollTo(0, 0)
      
  }

}

const supprimerPhoto = async (e, id) => {

  let t = e.target.parentNode

  t.style.opacity = 0.5

  let results = await Annonces.supprimerPhoto(id)

  if (results.success){

      t.style.display = 'none'

  }else{

      alert(results.message)

  }

}

const uploadVideos = (e, id, setError, setVideos, videos, setLoad) => {

  let files = e.target.files
      
  uploadv(id, files[0], setVideos, videos, setError, setLoad)

}

const uploadv = async (id, file, setVideos, videos, setError, setLoad) => {

  if(file.size == 0 || file.name == null){

    setError("Impossible d'ajouter cette video une erreur est servenu.")

    window.scrollTo(0, 0)

    return false;

  }

  if(file.size > 52428800){

    setError("Veuillez choisir un fichier vidéo de moins de 50Mo d'espace disque.")

    window.scrollTo(0, 0)

    return false

  }

  setLoad(true)

  let results = await Annonces.videoUpload(file, id)

  setLoad(false)

  if (results.success){

      setVideos(results.data)

  }else{

      Func.alert(results.message)
      
  }

}

const supprimerVideo = async (e, id) => {

  let t = e.target.parentNode

  t.style.opacity = 0.5

  let results = await Annonces.supprimerVideo(id)

  if (results.success){

      t.style.display = 'none'

  }else{

      alert(results.message)

  }

}

function EditArticle() {

  const {t} = useTranslation()

  const { id } = useParams()
  
  const navigate = useNavigate()

  const context = useContext(Context)

  const [error, setError] = useState('')
  
  const [infos, setInfos] = useState(false)

  const [categories, setCategories] = useState([])
  const [sousCategories, setSousCategories] = useState([])

  const [categorie, setCategorie] = useState(0)
  const [ssCategorie, setSsCategorie] = useState(0)

  const [livraison_indiv, setLivraison_indiv] = useState(0)

  const [livraison_tarif_special, setLivraison_tarif_special] = useState(0)

  const [loading, setLoading] = useState(true)

  const [photos, setPhotos] = useState([])

  const [photosUrls, setPhotosUrls] = useState([])

  const [load, setLoad] = useState(false)

  const [videos, setVideos] = useState()

  const [stock, setStock] = useState(0)

  const [required, setRequired] = useState("")

  const [commercialisation, setCommercialisation] = useState(0)

  const [conception, setConception] = useState("")

  const [variations, setVariations] = useState(0)

  const [personnaliser, setPersonnaliser] = useState(0)

  const [promotion, setPromotion] = useState(1)
    
  const [annonce, setAnnonce] = useState(false)
  
  const [flash, setFlash] = useState('')
  
  const [handmade, setHandmade] = useState(0)
  
  const [gratuit, setGratuit] = useState(0)
  
  const [emballage, setEmballage] = useState(0)

  const [occasion, setOccasion] = useState("")

  const [array, setArray] = useState([])

  const modifier = async (id, e, array) =>{

    e.preventDefault()
  
    setLoading(true)
  
    let inputs = e.target

    let occasions = []

    inputs.occasion.forEach(elem => {
        if(elem.checked) occasions.push(elem.value)
    })
  
    const results = await Annonces.modifier_annonce(id, inputs, array, occasions)
  
    setLoading(false)
  
    if(results.success){
  
      setFlash({type: 'succes', message: t('t187')})

      getAnnonce()
  
    }else{
  
      let t_msg = ''
      
      if(results.message == 'm4') t_msg = t('t188')
      if(results.message == 'm2') t_msg = t('t185')

      setFlash({type: 'error', message: t_msg})
  
    }
  
    window.scrollTo(0, 0)
  
  }

  const setPersonnaliserVar = (v) =>{
    setPersonnaliser(v)
  }

  const getAnnonce = async () => {
    
    let results = await Annonces.annoncesByIdEdit(id)

    if(results.success){

      setAnnonce(results.data)

      setPersonnaliserVar(results.data.personnalisation)
    
      setRequired(results.data.p_obligatoire)
      
      setPhotos(results.photos)

      setVideos(results.photos)

      setArray(results.options)

      setOccasion(results.occasions)

      setHandmade(results.data.handmade)
      setGratuit(results.data.livraisongratuite)
      setEmballage(results.data.emballage)

      setCategorie(results.data.categorie)
      setSsCategorie(results.data.souscategorie)

      setLivraison_indiv(results.data.livraison_indiv)
      setLivraison_tarif_special(results.data.livraison_special)

      getSousCategories(results.data.categorie)

      setStock(results.data.typeproduit)

      setPersonnaliser(results.data.personnalisation)

      setCommercialisation(results.data.stt)
      
      document.title = results.data.titre + " - " + APP_NAME

      setLoading(false)

    }else{
        navigate('/404')
    }
  }

  const getCategories = async () => {

    let results = await Categories.getCategories()

    setCategories(results.data)

  }

  const changeCategorie = (value) => {

    setCategorie(value)

    getSousCategories(value)

  }

  const getSousCategories = async (parent) => {

    let results = await Categories.getSousCategories(parent)

    setSousCategories(results.data)

  }

  const myInfos = async () => {

    let results = await User.session(2)

    if(results.success){
      
      if(results.data.livraison == null || results.data.delaislivraison == null || results.data.wilaya == '0'){

        Func.alert(t('t189'))

        navigate('/modifierboutique')

        return false

      }

      setInfos(results.data)

    }

  }

  const noWheel = (e) => {

    e.target.blur()

    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)

  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()
    
    getAnnonce()

    getCategories()

    document.title = "Modifier un article - " + APP_NAME

    window.scrollTo(0, 0)
    
  }, [])

  return (
    <div>
        <Header/>

        <HeaderBox/>
            
            {

              loading

              ?

              <div className='flex jc-center mt40 mb40'>
                <div className="lds-dual-ring col"></div>
              </div>

              :

            <div className='mt80 p30 cc brca mb30 p20m' id='article'>

              {(flash !== '') && <p className={flash.type}>{flash.message}</p>}

              {(error !== '') && <p className="error">{error}</p>}

              <form method="POST" className="form" onSubmit={(e) => modifier(id, e, array)}>
                
              <h1 className='ff fz18 mb30'>{t('t190')}</h1>
      
                <label>{t('t128')}</label>

                <input type="text" name="titre" placeholder={t('t128')} required="true" className='bg7' defaultValue={annonce.titre}/>

                {

                annonce && categorie && ssCategorie

                &&

                <>

                <label>{t('t129')}</label>
                
                {

                  categories

                  &&

                  <select name="categorie" required={true} className='bg7' onChange={e => changeCategorie(e.target.value)}>
                      
                    { categories.map(categorie => <option selected={ categorie.idC == annonce.categorie } value={categorie.idC}>{categorie.name}</option>) }

                  </select>

                }
    
                <label>{t('t130')}</label>

                {
    
                  sousCategories
    
                  &&

                    <select name='souscategorie' required={true} onChange={e => setSsCategorie(e.target.value)}>
                    
                      { sousCategories.map(categorie => <option selected={ categorie.idC == annonce.souscategorie } value={categorie.idC}>{categorie.name}</option>) }
                    
                    </select>
          
                }

                </>
                
              }

                <label>{t('t131')}</label>
                <p>{t('t132')}</p>
                  
                <div>
                    
                  {
                    photos

                  &&

                    <div className='grid6 p10 brca br8 mt20 mb20'>
                        {
                          photos.map(image =>

                            image.type == 1

                            &&

                            <div key={image.idI} style={{width: '100px', margin: '10px'}} className='brca'>
                              <img src={PHOTOS_ROOT + 'small/' + image.name + '.jpg'} style={{width: '100px',height: '100px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover'}} />
                              <span className='btn3 cl1 bg9 hover-bg2' onClick={(e) => supprimerPhoto(e, image.idI)}><span className="icon mr5" >o</span>{t('sup')}</span>
                            </div>

                          )
                        }
                    </div>
                  }
                  
                  { photosUrls.map(image => <img src={image} style={{width: '100px',height: '100px', margin: '10px', borderRadius: '5px', objectFit: 'cover'}} />) }        
                
                  
                  { 

                    loading

                    ?

                      <div className='flex jc-center mt50 mb50'>
                        <div className="lds-dual-ring col"></div>
                      </div>

                    :
                      <div className='mb20'>
                        <input type="file" name="uploadPhotosInput" id="uploadPhotosInput" className="inputfile " data-multiple-caption="{count} files selected" multiple accept='image/*'  maxLength='9' onChange={ e => uploadPhotos(e, id, setError, setPhotos, photos, setLoading) } />
                        <label for="uploadPhotosInput"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz12'>{t('t135')}</span></label>    
                      </div> 
                    
                    }

                </div>
                 
                <label className='fz15 mt40'>{t('t133')}</label>
                  <p className='lh'>{t('t134')}</p>
                
                  { 

                    load

                    ?

                    <div className='flex jc-center mt20 mb50'>
                      <div className="lds-dual-ring col"></div>
                    </div>

                    :

                    <div className="mb40">
                      <input type="file" name="videos" id="file-8" className="inputfile" accept='video/*'  maxLength='9' onChange={ e => uploadVideos(e, id, setError, setVideos, videos, setLoad) } />
                      <label htmlFor="file-8"><span className='icon cl2 fz2 mr10'>X</span><span className='cl2 fz12'>{t('t136')}</span></label>
                    </div>

                  }

                  { 
                
                    videos

                    &&
                    
                    <div className='flex ai-center jc-center m0a p10 br8'>

                      {
                      
                        videos.map(video =>

                          video.type == 2

                            &&

                            <div style={{ margin: '10px'}}>

                              <video src={VIDEOS_ROOT + video.name} controls style={{width: '300px',height: '300px', marginBottom: '10px', borderRadius: '5px', objectFit: 'cover'}} />
                              <span className='btn3 cl1 bg9 hover-bg2 flex ai-center col-4 flex-start' onClick={(e) => supprimerVideo(e, video.idI)}><span className="icon mr5" >o</span>{t('sup')}</span>

                            </div>

                        )
                      }

                    </div>
                  
                  }

                  <label className='fz15'>{t('t137')}</label>
                  <textarea name='description' className='bg7' required="true" rows={"6"} defaultValue={annonce.description}></textarea>

                  <label className='fz15'>{t('t138')}</label>
                  
                  <select type="text" name="typeproduit" id='typeproduit' required="true" className='bg7' onChange={e => setStock(e.target.value)} defaultValue={annonce.typeproduit}>
                    <option value="1">{t('t139')}</option>
                    <option value="0">{t('t140')}</option>
                  </select>

                      <div className={stock == 1 ? 'dnone' : ''}>
                        <label className='fz15'>{t('t141')}</label>
                        <input type="number" name="quantity" placeholder={t('t141')} required="true" className='bg7' defaultValue={annonce.quantity} min={0} onWheel={ e => noWheel(e) }/>
                      </div>
                  
                      <div className={stock == 0 ? 'dnone' : ''}>

                        <label className='fz15'>{t('t142')}</label>
                  
                        <select name="conception" required="true" className='bg7' onChange={e => setConception(e.target.value)}  defaultValue={annonce.conception}>
                          <option value='t143'>{t('t143')}</option>
                          <option value='t144'>{t('t144')}</option>
                          <option value='t145'>{t('t145')}</option>
                          <option value='t146'>{t('t146')}</option>
                          <option value='t147'>{t('t147')}</option>
                          <option value='t148'>{t('t148')}</option>
                          <option value='t149'>{t('t149')}</option>
                          <option value='t150'>{t('t150')}</option>
                          <option value='t151'>{t('t151')}</option>
                          <option value='t152'>{t('t152')}</option>
                        </select>
                      </div>
                    

                  <div className='grid2'>

                    <div>
                      <label className='fz15'>{t('prix')}</label>
                      <input type="number" name="prix" placeholder={t('prix')} required="true" className='bg7' defaultValue={annonce.prix_default} min={0} onWheel={ e => noWheel(e) }/>
                    </div>

                    <div>
                      <label className='fz15'>{t('t153')}</label>
                   
                      <select name='prixpromotion' onChange={(e)=> setPromotion(e.target.value)} defaultValue={annonce.promotion}>
                        <option value='0'>{t('t154')}</option>
                        <option value='10'>-10%</option>
                        <option value='20'>-20%</option>
                        <option value='30'>-30%</option>
                        <option value='40'>-40%</option>
                        <option value='50'>-50%</option>
                        <option value='60'>-60%</option>
                        <option value='70'>-70%</option>
                        <option value='80'>-80%</option>
                        <option value='90'>-90%</option>
                      </select>
                    
                    </div>

                  </div>

                  { annonce && <VariationsEdit stock={stock} array={array} setArray={ setArray }/> }

                  {

                  infos.livraison_moi_meme == 2

                  &&

                  <>

                    <label className='fz15 mt50'>{t('t155')}</label>
                    <input type="number" name="poids" className='bg7' placeholder={t('t155')} step={0.1} defaultValue={annonce.poids} required={true} min={0}/>

                    <label className='fz15 mt10'>{t('t156')}</label>

                    <label className='label'><input type="radio" name='livraison_indiv' className="mr10" value={1}  required checked={livraison_indiv == 1 ? true : false} onChange={e => setLivraison_indiv(1)}/>{t('oui')}</label>
                    <label className='label'><input type="radio" name='livraison_indiv' className="mr10" value={0} required checked={livraison_indiv == 0 ? true : false} onChange={e => setLivraison_indiv(0)}/>{t('non')}</label>

                  </>


                  }

                  {

                  livraison_indiv == 1

                  &&

                  <>
                    
                    <label className='fz15 mt50'>{t('t157')}</label>

                    <label className='label'><input type="radio" name='livraison_tarif_special' className="mr10" value={1}  required checked={livraison_tarif_special == 1 ? true : false} onChange={e => setLivraison_tarif_special(1)}/>{t('oui')}</label>
                    <label className='label'><input type="radio" name='livraison_tarif_special' className="mr10" value={0} required checked={livraison_tarif_special == 0 ? true : false} onChange={e => setLivraison_tarif_special(0)}/>{t('non')}</label>

                  </>

                  }

                  {

                  livraison_indiv == 1 && livraison_tarif_special == 1

                  &&

                  <>
                    
                    <label className='fz15 mt50'>{t('t158')}</label>

                    <input type="number" name="livraison_special_prix" className='bg7' placeholder={t('t159')} defaultValue={ annonce.livraison_special_prix } onWheel={ e => noWheel(e) }/>

                  </>

                  }

                  {

                  livraison_indiv == 1 && livraison_tarif_special == 0

                  &&

                  <p>{t('t160')}</p>

                  }

                  <label className='fz15 mt50'>{t('perso')}</label>
                
                  <label className='label'><input type="radio" name='personnalisation' className="mr10" value={1} onChange={e => setPersonnaliserVar(e.target.value)}  checked={personnaliser == 1 ? true : false}/>{t('oui')}</label>
                  <label className='label'><input type="radio" name='personnalisation' className="mr10" value={0} onChange={e => setPersonnaliserVar(e.target.value)} checked={personnaliser == 0 ? true : false}/>{t('non')}</label>

                  {

                  personnaliser == 1

                  &&

                  <>
                    <label className='fz15 mt50'>{t('perso')}</label>
                    <div className='flex jc-start'>

                      <label className='label mr50'><input type="radio" name='obligatoire' className="mr10" value={1} required checked={required == 1 ? true : false} onChange={e => setRequired(1)}/>{t('t161')}</label>
                      <label className='label'><input type="radio" name='obligatoire' className="mr10" value={0} required checked={required == 0 ? true : false} onChange={e => setRequired(0)}/>{t('t162')}</label>

                    </div>
                    
                    <label className='fz15'>{t('t163')}</label>

                    <textarea name='descpersonnaliser' className='bg7' required rows={"6"}  defaultValue={annonce.descpersonnaliser}></textarea>

                    {
                      required === 0 

                      &&

                      <input type="number" name="prixpersonnaliser" placeholder={t('t164')} required className='bg7' defaultValue={annonce.prixpersonnaliser} min={0} onWheel={ e => noWheel(e) }/>
                      
                    }

                  </>

                  }

                    <div>
                      <label className='fz15'>{t('t165')}</label>
                      <label className='label'><input type="checkbox" name='handmade' className="mr10" checked={handmade == 1 ? true : false} onChange={ e => e.target.checked == true ? setHandmade(1) : setHandmade(0)}/>{t('oui')}</label>
                    </div>

                    <div className='grid2 mt30 mb10'>
                     
                      <div>
                        <label className='fz15'>{t('livraisongratuite')}</label>
                        <label className='label'><input type="checkbox" name='livraisongratuite' className="mr10" checked={gratuit == 1 ? true : false} onChange={ e => e.target.checked == true ? setGratuit(1) : setGratuit(0)}/>{t('oui')}</label>
                      </div>
                    
                      <div>
                        <label className='fz15'>{t('t166')}</label>
                        <label className='label'><input type="checkbox" name='emballage' className="mr10" checked={emballage == 1 ? true : false} onChange={ e => e.target.checked == true ? setEmballage(1) : setEmballage(0)}/>{t('oui')}</label>
                      </div>

                  </div>
              

                  <label className='fz15'>{t('t167')}</label>

                  <div className='flex flex-wrap' style={{fontSize: '0.8em'}}>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='1' className='mr10' defaultChecked={Object.values(occasion).indexOf('1') !== -1 ? true : false}/>
                      <span>{t('t169_1')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='2' className='mr10' defaultChecked={Object.values(occasion).indexOf('2') !== -1 ? true : false}/>
                      <span>{t('t169')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='3' className='mr10' defaultChecked={Object.values(occasion).indexOf('3') !== -1 ? true : false}/>
                      <span>{t('t170')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='4' className='mr10' defaultChecked={Object.values(occasion).indexOf('4') !== -1 ? true : false}/>
                      <span>{t('t171')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='5' className='mr10' defaultChecked={Object.values(occasion).indexOf('5') !== -1 ? true : false}/>
                      <span>{t('t172')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='6' className='mr10' defaultChecked={Object.values(occasion).indexOf('6') !== -1 ? true : false}/>
                      <span>{t('t173')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='7' className='mr10' defaultChecked={Object.values(occasion).indexOf('7') !== -1 ? true : false}/>
                      <span>{t('t174')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='8' className='mr10' defaultChecked={Object.values(occasion).indexOf('8') !== -1 ? true : false}/>
                      <span>{t('t175')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='9' className='mr10' defaultChecked={Object.values(occasion).indexOf('9') !== -1 ? true : false}/>
                      <span>{t('t176')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='10' className='mr10' defaultChecked={Object.values(occasion).indexOf('10') !== -1 ? true : false}/>
                      <span>{t('t177')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='11' className='mr10' defaultChecked={Object.values(occasion).indexOf('11') !== -1 ? true : false}/>
                      <span>{t('t178')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='12' className='mr10' defaultChecked={Object.values(occasion).indexOf('12') !== -1 ? true : false}/>
                      <span>{t('t180')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='13' className='mr10' defaultChecked={Object.values(occasion).indexOf('13') !== -1 ? true : false}/>
                      <span>{t('t181')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='14' className='mr10' defaultChecked={Object.values(occasion).indexOf('14') !== -1 ? true : false}/>
                      <span>{t('t182')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='15' className='mr10' defaultChecked={Object.values(occasion).indexOf('15') !== -1 ? true : false}/>
                      <span>{t('t183')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='17' className='mr10' defaultChecked={Object.values(occasion).indexOf('17') !== -1 ? true : false}/>
                      <span>{t('t179')}</span>
                    </label>

                    <label className='col-3'>
                      <input type='checkbox' name='occasion' value='16' className='mr10' defaultChecked={Object.values(occasion).indexOf('16') !== -1 ? true : false}/>
                      <span>{t('t183_2')}</span>
                    </label>

                    <label className='col-3'></label>

                  </div>


                  {/* <select name="occasion" className='bg7' value={occasion} onChange={e => setOccasion()}>

                    <option value='0'>{t('t168')}</option>
                    <option value='2'>{t('t169')}</option>
                    <option value='3'>{t('t170')}</option>
                    <option value='4'>{t('t171')}</option>
                    <option value='5'>{t('t172')}</option>
                    <option value='6'>{t('t173')}</option>
                    <option value='7'>{t('t174')}</option>
                    <option value='8'>{t('t175')}</option>
                    <option value='9'>{t('t176')}</option>
                    <option value='10'>{t('t177')}</option>
                    <option value='11'>{t('t178')}</option>
                    <option value='16'>{t('t179')}</option>
                    <option value='12'>{t('t180')}</option>
                    <option value='13'>{t('t181')}</option>
                    <option value='14'>{t('t182')}</option>
                    <option value='15'>{t('t183')}</option>
                    <option value='16'>{t('t183_2')}</option>

                  </select> */}

                  {

                    (annonce.stt == '1' || annonce.stt == '80')

                    &&

                      <>
                      
                        <label className='fz15 mt20'>{t('t191')}</label>
                        
                        <div className='flex jc-start'>

                          <label className='label mr50'><input type="radio" name='commercialisation' className="mr10" value={1} required checked={commercialisation == 1 ? true : false} onChange={e => setCommercialisation(1)}/>{t('oui')}</label>
                          <label className='label'><input type="radio" name='commercialisation' className="mr10" value={80} required checked={commercialisation == 80 ? true : false} onChange={e => setCommercialisation(80)}/>{t('non')}</label>

                        </div>
                    
                      </>

                  }

                  <input type="submit" name="save" value={t('enr')} className="btn bg3 cl1 hover-bg2 hover-cl1 col-3"/>
        
              </form>

            </div>

            }


        <Footer/>
    </div>
  )
}

export default EditArticle

